import React from "react";
import cx from "classnames";
import "./Footer.css";
import logoImg from "img/logo_footer_big.svg";
import { NavLink } from "react-router-dom";
import { isHomeSite, isPublicHome, getAppBaseUrl, shouldShowRedirectModal } from "lib/legacy";
import { getFooterLinks, SOCIAL_LINKS } from "./constants";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import twitterIcon from "img/ic_twitter.svg";
import discordIcon from "img/ic_discord.svg";
import telegramIcon from "img/ic_telegram.svg";

type Props = { showRedirectModal?: (to: string) => void; redirectPopupTimestamp?: () => void };

export default function Footer({ showRedirectModal, redirectPopupTimestamp }: Props) {
  const isHome = isPublicHome();

  return (
    <div className="Footer">
      <div className={cx("Footer-wrapper", { home: isHome })}>


        <div className="Footer-social-link-block">
          {/* {!isPublicHome() && SOCIAL_LINKS.map((platform) => {
            return (
              <ExternalLink key={platform.name} className="App-social-link" href={platform.link}>
                <img src={platform.icon} alt={platform.name} />
              </ExternalLink>
            );
          })}
          {isPublicHome() && SOCIAL_LINKS.map((platform) => {
            return (
              <div className="Home-footer-sosioal-block">
                <ExternalLink key={platform.name} className="App-home-footer-social-link" href={platform.link}>
                  <img src={platform.icon} alt={platform.name} />
                </ExternalLink>
                <Trans>{platform.name}</Trans>
              </div>
            );
          })} */}

          <div className="Home-footer-sosioal-block">
            <ExternalLink key="Twitter" className="App-home-footer-social-link" href="https://twitter.com/sobax_dex?s=21&t=ooBAsgu6sa0yfCE-iUqXBg">
              <img src={twitterIcon} alt="Twitter" />
            </ExternalLink>
            <Trans>Twitter</Trans>
          </div>
          <div className="Home-footer-sosioal-block">
            <ExternalLink key="Discord" className="App-home-footer-social-link" href="https://discord.gg/QV42dJyfwV">
              <img src={discordIcon} alt="Discord" />
            </ExternalLink>
            <Trans>Discord</Trans>
          </div>
          <div className="Home-footer-sosioal-block">
            <ExternalLink key="Telegram" className="App-home-footer-social-link" href="https://t.me/+1gM9vRGuXrs2MDM1">
              <img src={telegramIcon} alt="Telegram" />
            </ExternalLink>
            <Trans>Telegram</Trans>
          </div>
        </div>
        <div className="Footer-logo">
          <img src={logoImg} alt="MetaMask" className="footer-logo"  />
        </div>
      </div>
    </div>
  );
}
