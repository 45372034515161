import { POLYGON } from "config/chains";
import { getContract } from "config/contracts";

const POLYGON_GMX = getContract(POLYGON, "GMX");

type Exchange = {
  name: string;
  icon: string;
  networks: number[];
  link?: string;
  links?: { [POLYGON]: string;[POLYGON]: string };
};

export const EXTERNAL_LINKS = {
  // TODO kani 優先高
  [POLYGON]: {
    bungee: `https://multitx.bungee.exchange/?toChainId=5&toTokenAddress=${POLYGON_GMX}`,
    banxa: "https://gmx.banxa.com/?coinType=ETH&fiatType=USD&fiatAmount=500&blockchain=polygon",
    networkWebsite: "https://ethereum.org/",
    buyGmx: {
      banxa: "https://gmx.banxa.com/?coinType=GMX&fiatType=USD&fiatAmount=500&blockchain=polygon",
      uniswap: `https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=${POLYGON_GMX}`,
    },
  },
};

export const TRANSFER_EXCHANGES: Exchange[] = [
];

export const CENTRALISED_EXCHANGES: Exchange[] = [
];

export const DECENTRALISED_AGGRIGATORS: Exchange[] = [
];
