import { createClient } from "./utils";
import { SUBGRAPH_URLS } from "config/subgraph";
import { POLYGON } from "config/chains";

export const chainlinkClient = createClient(SUBGRAPH_URLS.common.chainLink);

export const polygonGraphClient = createClient(SUBGRAPH_URLS[POLYGON].stats);
export const polygonReferralsGraphClient = createClient(SUBGRAPH_URLS[POLYGON].referrals);
export const polygonGraphClientForTrades = createClient(SUBGRAPH_URLS[POLYGON].trades);

export function getGmxGraphClient(chainId: number) {
  if (chainId === POLYGON) {
    return polygonGraphClient;
  }

  throw new Error(`Unsupported chain ${chainId}`);
}

export function getGmxGraphClientByNewCreate(chainId: number) {
  if (chainId === POLYGON) {
    return createClient(SUBGRAPH_URLS[POLYGON].stats);
  }

  throw new Error(`Unsupported chain ${chainId}`);
}