import { ethers } from "ethers";
import { isDevelopment } from "lib/legacy";

const { parseEther } = ethers.utils;

export const POLYGON = 7001;

// TODO take it from web3
export const DEFAULT_CHAIN_ID = POLYGON;
export const CHAIN_ID = DEFAULT_CHAIN_ID;

export const SUPPORTED_CHAIN_IDS = [POLYGON];

if (isDevelopment()) {
  // TODO kani
  //SUPPORTED_CHAIN_IDS.push(ARBITRUM_TESTNET);
  ;
}

export const IS_NETWORK_DISABLED = {
  [POLYGON]: false,
};

export const CHAIN_NAMES_MAP = {
  [POLYGON]: "Zetachain Athens 3",
};

export const GAS_PRICE_ADJUSTMENT_MAP = {
  [POLYGON]: "3000000000", // 3 gwei
};

export const MAX_GAS_PRICE_MAP = {
  [POLYGON]: "200000000000", // 200 gwei
};

export const HIGH_EXECUTION_FEES_MAP = {
  [POLYGON]: 3, // 3 USD
};

const constants = {
  [POLYGON]: {
    nativeTokenSymbol: "ZETA",
    wrappedTokenSymbol: "WZETA",
    defaultCollateralSymbol: "USDC.ETH",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: true,

    // TODO kani
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.01"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.01"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0100001"),
  },
};

const ALCHEMY_WHITELISTED_DOMAINS = ["sobax.app", "app.sobax.app", "zeta-testnet.sobax.app", "zeta-testnet-app.sobax.app"];

export const POLYGON_RPC_PROVIDERS = ["https://zetachain-athens-evm.blockpi.network/v1/rpc/public"]; //MAINNET

export const RPC_PROVIDERS = {
  [POLYGON]: POLYGON_RPC_PROVIDERS,
};

// TODO kani
export const FALLBACK_PROVIDERS = {
  [POLYGON]: ["https://zetachain-athens-evm.blockpi.network/v1/rpc/public"],
};

export const NETWORK_METADATA = {
  [POLYGON]: {
    chainId: "0x" + POLYGON.toString(16),
    chainName: "ZetaChain Athens 3 Testnet",
    nativeCurrency: {
      name: "ZETA",
      symbol: "ZETA",
      decimals: 18,
    },
    rpcUrls: POLYGON_RPC_PROVIDERS,
    blockExplorerUrls: [getExplorerUrl(POLYGON)],
  },
};

export const getConstant = (chainId: number, key: string) => {
  if (!constants[chainId]) {
    throw new Error(`Unsupported chainId ${chainId}`);
  }

  if (!(key in constants[chainId])) {
    throw new Error(`Key ${key} does not exist for chainId ${chainId}`);
  }

  return constants[chainId][key];
};

export function getChainName(chainId: number) {
  return CHAIN_NAMES_MAP[chainId];
}

export function getDefaultArbitrumRpcUrl() {
  return "https://arb1.arbitrum.io/rpc";
}

export function getAlchemyHttpUrl() {
  if (ALCHEMY_WHITELISTED_DOMAINS.includes(window.location.host)) {
    return "https://arb-mainnet.g.alchemy.com/v2/ha7CFsr1bx5ZItuR6VZBbhKozcKDY4LZ";
  }
  return "https://arb-mainnet.g.alchemy.com/v2/EmVYwUw0N2tXOuG0SZfe5Z04rzBsCbr2";
}

export function getAlchemyWsUrl() {
  if (ALCHEMY_WHITELISTED_DOMAINS.includes(window.location.host)) {
    return "wss://arb-mainnet.g.alchemy.com/v2/ha7CFsr1bx5ZItuR6VZBbhKozcKDY4LZ";
  }
  return "wss://arb-mainnet.g.alchemy.com/v2/EmVYwUw0N2tXOuG0SZfe5Z04rzBsCbr2";
}

export function getExplorerUrl(chainId) {
  if (chainId === POLYGON) {
    // TODO kani
    return "https://athens.explorer.zetachain.com/";
  }
  return "https://athens.explorer.zetachain.com/";
}

export function getHighExecutionFee(chainId) {
  return HIGH_EXECUTION_FEES_MAP[chainId] || 3;
}

export function isSupportedChain(chainId) {
  return SUPPORTED_CHAIN_IDS.includes(chainId);
}
