import React from "react";
import Footer from "components/Footer/Footer";
import "./Home.css";
import { Users, BarChartBig, RefreshCw, DollarSign, TrendingUp, HardDriveDownload, ReplaceAll} from "lucide-react"

import simpleSwapIcon from "img/ic_simpleswaps.svg";
import costIcon from "img/ic_cost.svg";
import liquidityIcon from "img/ic_liquidity.svg";
import totaluserIcon from "img/ic_totaluser.svg";

import arbitrumIcon from "img/ic_arbitrum_96.svg";
import avaIcon from "img/ic_avalanche_96.svg";
import goerliIcon from "img/ic_goerli_96.svg";
import polygonIcon from "img/ic_polygon_96.svg";

import statsIcon from "img/ic_stats.svg";
import tradingIcon from "img/ic_trading.svg";
import c_benefit from "img/c-benefit-img.png";

import useSWR from "swr";

import { USD_DECIMALS, getTotalVolumeSum } from "lib/legacy";

import { useTradersData, useUserStat, useVolumeData } from "domain/legacy";

import TokenCard from "components/TokenCard/TokenCard";
import { Trans } from "@lingui/macro";
import { HeaderLink } from "components/Header/HeaderLink";
import { ARBITRUM, AVALANCHE, GOERLI_TESTNET, POLYGON } from "config/chains";
import { getServerUrl } from "config/backend";
import { bigNumberify, formatAmount, numberWithCommas } from "lib/numbers";
import { formatNumber } from "domain/legacy";

export default function Home({ showRedirectModal, redirectPopupTimestamp }) {
  // const [openedFAQIndex, setOpenedFAQIndex] = useState(null)
  // const faqContent = [{
  //   id: 1,
  //   question: "What is GMX?",
  //   answer: "GMX is a decentralized spot and perpetual exchange that supports low swap fees and zero price impact trades.<br><br>Trading is supported by a unique multi-asset pool that earns liquidity providers fees from market making, swap fees, leverage trading (spreads, funding fees & liquidations), and asset rebalancing.<br><br>Dynamic pricing is supported by Chainlink Oracles along with TWAP pricing from leading volume DEXs."
  // }, {
  //   id: 2,
  //   question: "What is the SBX Governance Token? ",
  //   answer: "The SBX token is the governance token of the SBX ecosystem, it provides the token owner voting rights on the direction of the SBX platform.<br><br>Additionally, when SBX is staked you will earn 30% of the platform-generated fees, you will also earn Escrowed SBX tokens and Multiplier Points."
  // }, {
  //   id: 3,
  //   question: "What is the SBLP Token? ",
  //   answer: "The SBLP token represents the liquidity users provide to the SBX platform for Swaps and Margin Trading.<br><br>To provide liquidity to SBLP you <a href='https://gmx.io/buy_sblp' target='_blank'>trade</a> your crypto asset BTC, ETH, LINK, UNI, USDC, USDT, MIM, or FRAX to the liquidity pool, in exchange, you gain exposure to a diversified index of tokens while earning 50% of the platform trading fees and esSBX."
  // }, {
  //   id: 4,
  //   question: "What can I trade on GMX? ",
  //   answer: "On SBX you can swap or margin trade any of the following assets: ETH, BTC, LINK, UNI, USDC, USDT, MIM, FRAX, with others to be added. "
  // }]

  // const toggleFAQContent = function(index) {
  //   if (openedFAQIndex === index) {
  //     setOpenedFAQIndex(null)
  //   } else {
  //     setOpenedFAQIndex(index)
  //   }
  // }

  // ARBITRUM

  // const arbitrumPositionStatsUrl = getServerUrl(ARBITRUM, "/position_stats");
  // const { data: arbitrumPositionStats } = useSWR([arbitrumPositionStatsUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  // const arbitrumTotalVolumeUrl = getServerUrl(ARBITRUM, "/total_volume");
  // const { data: arbitrumTotalVolume } = useSWR([arbitrumTotalVolumeUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  // // AVALANCHE

  // const avalanchePositionStatsUrl = getServerUrl(AVALANCHE, "/position_stats");
  // const { data: avalanchePositionStats } = useSWR([avalanchePositionStatsUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  // const avalancheTotalVolumeUrl = getServerUrl(AVALANCHE, "/total_volume");
  // const { data: avalancheTotalVolume } = useSWR([avalancheTotalVolumeUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  // // GOERLI

  // const goerliPositionStatsUrl = getServerUrl(GOERLI_TESTNET, "/position_stats");
  // const { data: goerliPositionStats } = useSWR([goerliPositionStatsUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  // const goerliTotalVolumeUrl = getServerUrl(GOERLI_TESTNET, "/total_volume");
  // const { data: goerliTotalVolume } = useSWR([goerliTotalVolumeUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  // // POLYGON

  // const polygonPositionStatsUrl = getServerUrl(POLYGON, "/position_stats");
  // const { data: polygonPositionStats } = useSWR([polygonPositionStatsUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  // const polygonTotalVolumeUrl = getServerUrl(POLYGON, "/total_volume");
  // const { data: polygonTotalVolume } = useSWR([polygonTotalVolumeUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  // // Total Volume

  // const arbitrumTotalVolumeSum = getTotalVolumeSum(arbitrumTotalVolume);
  // const avalancheTotalVolumeSum = getTotalVolumeSum(avalancheTotalVolume);
  // const goerliTotalVolumeSum = getTotalVolumeSum(goerliTotalVolume);
  // const polygonTotalVolumeSum = getTotalVolumeSum(polygonTotalVolume);

  // let totalVolumeSum = bigNumberify(0);
  // if (arbitrumTotalVolumeSum && avalancheTotalVolumeSum) {
  //   totalVolumeSum = totalVolumeSum.add(arbitrumTotalVolumeSum);
  //   totalVolumeSum = totalVolumeSum.add(avalancheTotalVolumeSum);
  // }
  // if (goerliTotalVolumeSum) {
  //   totalVolumeSum = totalVolumeSum.add(goerliTotalVolumeSum);
  // }
  // if (polygonTotalVolumeSum) {
  //   totalVolumeSum = totalVolumeSum.add(polygonTotalVolumeSum);
  // }

  // Open Interest

  // let openInterest = bigNumberify(0);
  // if (
  //   arbitrumPositionStats &&
  //   arbitrumPositionStats.totalLongPositionSizes &&
  //   arbitrumPositionStats.totalShortPositionSizes
  // ) {
  //   openInterest = openInterest.add(arbitrumPositionStats.totalLongPositionSizes);
  //   openInterest = openInterest.add(arbitrumPositionStats.totalShortPositionSizes);
  // }

  // if (
  //   avalanchePositionStats &&
  //   avalanchePositionStats.totalLongPositionSizes &&
  //   avalanchePositionStats.totalShortPositionSizes
  // ) {
  //   openInterest = openInterest.add(avalanchePositionStats.totalLongPositionSizes);
  //   openInterest = openInterest.add(avalanchePositionStats.totalShortPositionSizes);
  // }

  // if (
  //   goerliPositionStats &&
  //   goerliPositionStats.totalLongPositionSizes &&
  //   goerliPositionStats.totalShortPositionSizes
  // ) {
  //   openInterest = openInterest.add(goerliPositionStats.totalLongPositionSizes);
  //   openInterest = openInterest.add(goerliPositionStats.totalShortPositionSizes);
  // }

  // if (
  //   polygonPositionStats &&
  //   polygonPositionStats.totalLongPositionSizes &&
  //   polygonPositionStats.totalShortPositionSizes
  // ) {
  //   openInterest = openInterest.add(polygonPositionStats.totalLongPositionSizes);
  //   openInterest = openInterest.add(polygonPositionStats.totalShortPositionSizes);
  // }
  const [totalVolumeData, totalVolumeLoading] = useVolumeData()

  const [openInterest, openInterestDelta] = useTradersData()
  // user stat
  // const arbitrumUserStats = useUserStat(ARBITRUM);
  // const avalancheUserStats = useUserStat(AVALANCHE);
  // const goerliUserStats = useUserStat(GOERLI_TESTNET);
  const polygonUserStats = useUserStat(POLYGON);
  let totalUsers = 0;

  // if (arbitrumUserStats && arbitrumUserStats.uniqueCount) {
  //   totalUsers += arbitrumUserStats.uniqueCount;
  // }

  // if (avalancheUserStats && avalancheUserStats.uniqueCount) {
  //   totalUsers += avalancheUserStats.uniqueCount;
  // }

  // if (goerliUserStats && goerliUserStats.uniqueCount) {
  //   totalUsers += goerliUserStats.uniqueCount;
  // }

  if (polygonUserStats && polygonUserStats.uniqueCount) {
    totalUsers += polygonUserStats.uniqueCount;
  }

  const LaunchExchangeButton = () => {
    return (

      <HeaderLink
        className="content-skew-button"
        to="/trade"
        redirectPopupTimestamp={redirectPopupTimestamp}
        showRedirectModal={showRedirectModal}
      >
        <Trans>Launch Exchange</Trans>
      </HeaderLink>

    );
  };

  return (
    <div className="Home">
      <div className="Home-top">
        <div className="Home-title-section-container default-container">
          <div className="Home-title-section">
            <div className="Home-title">
              <Trans>
                Decentralized
                <br />
                Perpetual Exchange
              </Trans>
            </div>
            <div className="Home-description">
              <Trans>
                Trade BTC, ETH, Zeta and other top cryptocurrencies with up to 100x leverage directly from your wallet
              </Trans>
            </div>
            <div>
              <LaunchExchangeButton />
            </div>
              
          </div>
        </div>
        <div className="Home-latest-info-container default-container">
          <div className="Home-latest-info-block">
            <DollarSign className="custom-icon-size" />
            {/* <img src={tradingIcon} alt="Sblp Icon" className="Home-latest-info__icon" /> */}
            {/* kani TODO */}
            {/* <div className="Home-latest-info-content">
              <div className="Home-latest-info__title">
                <Trans>Total Trading Volume</Trans>
              </div>
              <div className="Home-latest-info__value">{formatNumber(totalVolumeData, { currency: true, compact: false })}</div>
            </div> */}
            <div className="Home-latest-info__value">{formatNumber(0, { currency: true, compact: false })}</div>
            <div className="Home-latest-info__title">
              <Trans>Total Fees</Trans>
            </div>
          </div>
          <div className="Home-latest-info-block">
            <RefreshCw className="custom-icon-size" />
            {/* <img src={tradingIcon} alt="Sblp Icon" className="Home-latest-info__icon" /> */}
            {/* kani TODO */}
            {/* <div className="Home-latest-info-content">
              <div className="Home-latest-info__title">
                <Trans>Total Trading Volume</Trans>
              </div>
              <div className="Home-latest-info__value">{formatNumber(totalVolumeData, { currency: true, compact: false })}</div>
            </div> */}
            <div className="Home-latest-info__value">{formatNumber(totalVolumeData, { currency: true, compact: false })}</div>
            <div className="Home-latest-info__title">
              <Trans>Total Trading Volume</Trans>
            </div>
          </div>
          <div className="Home-latest-info-block">
            <BarChartBig className="custom-icon-size" />
            {/* <img src={statsIcon} alt="Open Interest Icon" className="Home-latest-info__icon" /> */}
            {/* kani TODO */}
            {/* <div className="Home-latest-info-content">
              <div className="Home-latest-info__title">
                <Trans>Open Interest</Trans>
              </div>
              <div className="Home-latest-info__value">{openInterest? formatNumber(openInterest, { currency: true, compact: false }) : '$0'}</div>
            </div> */}
            <div className="Home-latest-info__value">{openInterest ? formatNumber(openInterest, { currency: true, compact: false }) : '$0'}</div>
            <div className="Home-latest-info__title">
              <Trans>Open Interest</Trans>
            </div>

          </div>
          <div className="Home-latest-info-block">
            <Users className="custom-icon-size" />
            {/* <img src={totaluserIcon} alt="Total Users Icon" className="Home-latest-info__icon" /> */}
            {/* <div className="Home-latest-info-content">
              <div className="Home-latest-info__title">
                <Trans>Total Users</Trans>
              </div>
              <div className="Home-latest-info__value">{numberWithCommas(totalUsers.toFixed(0))}</div>
            </div> */}
            <div className="Home-latest-info__value">{numberWithCommas(totalUsers.toFixed(0))}</div>
            <div className="Home-latest-info__title">
              <Trans>Total Users</Trans>
            </div>
          </div>
        </div>
      </div>



      <div className="Home-benefits-section">
        <div className="default-container">
          <div className="c-benefits-main">
            <div className="Home-benefits default-container">
              <div className="Home-benefit">
                <div className="Home-benefit-icon">
                  <TrendingUp className="custom-section-icon-size" />
                  {/* <img src={liquidityIcon} alt="Reduce Liquidation Risks Icon" className="Home-benefit-icon-symbol" /> */}
                  <div className="Home-benefit-title">
                    <Trans>Reduce Liquidation Risks</Trans>
                  </div>
                </div>
                <div className="Home-benefit-description">
                  <Trans>
                    An aggregate of high-quality price feeds determine when liquidations occur. This keeps positions safe
                    from temporary wicks.
                  </Trans>
                </div>
              </div>
              <div className="Home-benefit">
                <div className="Home-benefit-icon">
                  <HardDriveDownload className="custom-section-icon-size" />
                  {/* <img src={costIcon} alt="Save on Costs Icon" className="Home-benefit-icon-symbol" /> */}
                  <div className="Home-benefit-title">
                    <Trans>Save on Costs</Trans>
                  </div>
                </div>
                <div className="Home-benefit-description">
                  <Trans>
                    Enter and exit positions with minimal spread and zero price impact. Get the optimal price without
                    incurring additional costs.
                  </Trans>
                </div>
              </div>
              <div className="Home-benefit">
                <div className="Home-benefit-icon">
                  <ReplaceAll className="custom-section-icon-size" />
                  {/* <img src={simpleSwapIcon} alt="Simple Swaps Icon" className="Home-benefit-icon-symbol" /> */}
                  <div className="Home-benefit-title">
                    <Trans>Simple Swaps</Trans>
                  </div>
                </div>
                <div className="Home-benefit-description">
                  <Trans>
                    Open positions through a simple swap interface. Conveniently swap from any supported asset into the
                    position of your choice.
                  </Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className="Home-cta-section">
        <div className="Home-cta-container default-container">
          <div className="Home-cta-info">
            <div className="Home-cta-info__title">
              <Trans>Available on your preferred network</Trans>
            </div>
            <div className="Home-cta-info__description">
                <Trans>SBX is currently live on Zeta.</Trans>
                <LaunchExchangeButton />
            </div>
          </div>
          {/* <div className="Home-cta-options">
            <div className="Home-cta-option Home-cta-option-arbitrum">
              <div className="Home-cta-option-icon">
                <img src={polygonIcon} alt="Zeta Icon" />
              </div>
              <div className="Home-cta-option-info">
                <div className="Home-cta-option-title">Zetachain</div>
                <div className="Home-cta-option-action">
                  <LaunchExchangeButton />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="Home-token-card-section">
        <div className="Home-token-card-container default-container">
          <div className="Home-token-card-info">
            <div className="Home-token-card-info__title">
              <Trans>Two tokens create our ecosystem</Trans>
            </div>
          </div>
          <TokenCard showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} />
        </div>
      </div>

      {/* <div className="Home-video-section">
        <div className="Home-video-container default-container">
          <div className="Home-video-block">
            <img src={bluBigIcon} alt="gmxbig" />
          </div>
        </div>
      </div> */}
      {/* <div className="Home-faqs-section">
        <div className="Home-faqs-container default-container">
          <div className="Home-faqs-introduction">
            <div className="Home-faqs-introduction__title">FAQs</div>
            <div className="Home-faqs-introduction__description">Most asked questions. If you wish to learn more, please head to our Documentation page.</div>
            <a href="https://sobax.gitbook.io/sobax/" className="default-btn Home-faqs-documentation">Documentation</a>
          </div>
          <div className="Home-faqs-content-block">
            {
              faqContent.map((content, index) => (
                <div className="Home-faqs-content" key={index} onClick={() => toggleFAQContent(index)}>
                  <div className="Home-faqs-content-header">
                    <div className="Home-faqs-content-header__icon">
                      {
                        openedFAQIndex === index ? <FiMinus className="opened" /> : <FiPlus className="closed" />
                      }
                    </div>
                    <div className="Home-faqs-content-header__text">
                      { content.question }
                    </div>
                  </div>
                  <div className={ openedFAQIndex === index ? "Home-faqs-content-main opened" : "Home-faqs-content-main" }>
                    <div className="Home-faqs-content-main__text">
                      <div dangerouslySetInnerHTML={{__html: content.answer}} >
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div> */}
      <Footer showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} />
    </div>
  );
}
