import { POLYGON } from "./chains";

export const XGMT_EXCLUDED_ACCOUNTS = [
  "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
  "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
  "0xa633158288520807f91ccc98aa58e0ea43acb400",
  "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
];

const CONTRACTS = {
  // [MAINNET]: {
  //   // bsc mainnet
  //   Treasury: "0xa44E7252a0C137748F523F112644042E5987FfC7",
  //   BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
  //   GMT: "0x99e92123eB77Bc8f999316f622e5222498438784",
  //   Vault: "0xc73A8DcAc88498FD4b4B1b2AaA37b0a2614Ff67B",
  //   Router: "0xD46B23D042E976F8666F554E928e0Dc7478a8E1f",
  //   Reader: "0x087A618fD25c92B61254DBe37b09E5E8065FeaE7",
  //   AmmFactory: "0xBCfCcbde45cE874adCB698cC183deBcF17952812",
  //   AmmFactoryV2: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
  //   OrderBook: "0x1111111111111111111111111111111111111111",
  //   OrderBookReader: "0x1111111111111111111111111111111111111111",
  //   GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
  //   USDG: "0x85E76cbf4893c1fbcB34dCF1239A91CE2A4CF5a7",
  //   NATIVE_TOKEN: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  //   XGMT: "0xe304ff0983922787Fd84BC9170CD21bF78B16B10",
  //   GMT_USDG_PAIR: "0xa41e57459f09a126F358E118b693789d088eA8A0",
  //   XGMT_USDG_PAIR: "0x0b622208fc0691C2486A3AE6B7C875b4A174b317",
  //   GMT_USDG_FARM: "0x3E8B08876c791dC880ADC8f965A02e53Bb9C0422",
  //   XGMT_USDG_FARM: "0x68D7ee2A16AB7c0Ee1D670BECd144166d2Ae0759",
  //   USDG_YIELD_TRACKER: "0x0EF0Cf825B8e9F89A43FfD392664131cFB4cfA89",
  //   XGMT_YIELD_TRACKER: "0x82A012A9b3003b18B6bCd6052cbbef7Fa4892e80",
  //   GMT_USDG_FARM_TRACKER_XGMT: "0x08FAb024BEfcb6068847726b2eccEAd18b6c23Cd",
  //   GMT_USDG_FARM_TRACKER_NATIVE: "0xd8E26637B34B2487Cad1f91808878a391134C5c2",
  //   XGMT_USDG_FARM_TRACKER_XGMT: "0x026A02F7F26C1AFccb9Cba7C4df3Dc810F4e92e8",
  //   XGMT_USDG_FARM_TRACKER_NATIVE: "0x22458CEbD14a9679b2880147d08CA1ce5aa40E84",
  //   AUTO: "0xa184088a740c695E156F91f5cC086a06bb78b827",
  //   AUTO_USDG_PAIR: "0x0523FD5C53ea5419B4DAF656BC1b157dDFE3ce50",
  //   AUTO_USDG_FARM: "0xE6958298328D02051769282628a3b4178D0F3A47",
  //   AUTO_USDG_FARM_TRACKER_XGMT: "0x093b8be41c1A30704De84a9521632f9a139c08bd",
  //   AUTO_USDG_FARM_TRACKER_NATIVE: "0x23ed48E5dce3acC7704d0ce275B7b9a0e346b63A",
  //   GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
  //   XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
  //   GMT_USDG_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
  //   XGMT_USDG_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
  // },
  // [TESTNET]: {
  //   // bsc testnet
  //   Vault: "0x1B183979a5cd95FAF392c8002dbF0D5A1C687D9a",
  //   Router: "0x10800f683aa564534497a5b67F45bE3556a955AB",
  //   Reader: "0x98D4742F1B6a821bae672Cd8721283b91996E454",
  //   AmmFactory: "0x6725f303b657a9451d8ba641348b6761a6cc7a17",
  //   AmmFactoryV2: "0x1111111111111111111111111111111111111111",
  //   OrderBook: "0x9afD7B4f0b58d65F6b2978D3581383a06b2ac4e9",
  //   OrderBookReader: "0x0713562970D1A802Fa3FeB1D15F9809943982Ea9",
  //   GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
  //   USDG: "0x2D549bdBf810523fe9cd660cC35fE05f0FcAa028",
  //   GMT: "0xedba0360a44f885ed390fad01aa34d00d2532817",
  //   NATIVE_TOKEN: "0x612777Eea37a44F7a95E3B101C39e1E2695fa6C2",
  //   XGMT: "0x28cba798eca1a3128ffd1b734afb93870f22e613",
  //   GMT_USDG_PAIR: "0xe0b0a315746f51932de033ab27223d85114c6b85",
  //   XGMT_USDG_PAIR: "0x0108de1eea192ce8448080c3d90a1560cf643fa0",
  //   GMT_USDG_FARM: "0xbe3cB06CE03cA692b77902040479572Ba8D01b0B",
  //   XGMT_USDG_FARM: "0x138E92195D4B99CE3618092D3F9FA830d9A69B4b",
  //   USDG_YIELD_TRACKER: "0x62B49Bc3bF252a5DB26D88ccc7E61119e3179B4f",
  //   XGMT_YIELD_TRACKER: "0x5F235A582e0993eE9466FeEb8F7B4682993a57d0",
  //   GMT_USDG_FARM_TRACKER_XGMT: "0x4f8EE3aE1152422cbCaFACd4e3041ba2D859913C",
  //   GMT_USDG_FARM_TRACKER_NATIVE: "0xd691B26E544Fe370f39A776964c991363aF72e56",
  //   XGMT_USDG_FARM_TRACKER_XGMT: "0xfd5617CFB082Ba9bcD62d654603972AE312bC695",
  //   XGMT_USDG_FARM_TRACKER_NATIVE: "0x0354387DD85b7D8aaD1611B3D167A384d6AE0c28",
  //   GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
  //   XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
  //   GMT_USDG_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
  //   XGMT_USDG_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
  // },
  // [ARBITRUM_TESTNET]: {
  //   // arbitrum testnet
  //   Vault: "0xBc9BC47A7aB63db1E0030dC7B60DDcDe29CF4Ffb",
  //   Router: "0xe0d4662cdfa2d71477A7DF367d5541421FAC2547",
  //   VaultReader: "0xFc371E380262536c819D12B9569106bf032cC41c",
  //   Reader: "0x2E093c70E3A7E4919611d2555dFd8D697d2fC0a1",
  //   GlpManager: "0xD875d99E09118d2Be80579b9d23E83469077b498",
  //   RewardRouter: "0x0000000000000000000000000000000000000000",
  //   RewardReader: "0x0000000000000000000000000000000000000000",
  //   NATIVE_TOKEN: "0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681",
  //   GLP: "0xb4f81Fa74e06b5f762A104e47276BA9b2929cb27",
  //   GMX: "0x0000000000000000000000000000000000000000",
  //   ES_GMX: "0x0000000000000000000000000000000000000000",
  //   BN_GMX: "0x0000000000000000000000000000000000000000",
  //   USDG: "0xBCDCaF67193Bf5C57be08623278fCB69f4cA9e68",
  //   ES_GMX_IOU: "0x0000000000000000000000000000000000000000",
  //   StakedGmxTracker: "0x0000000000000000000000000000000000000000",
  //   BonusGmxTracker: "0x0000000000000000000000000000000000000000",
  //   FeeGmxTracker: "0x0000000000000000000000000000000000000000",
  //   StakedGlpTracker: "0x0000000000000000000000000000000000000000",
  //   FeeGlpTracker: "0x0000000000000000000000000000000000000000",

  //   StakedGmxDistributor: "0x0000000000000000000000000000000000000000",
  //   StakedGlpDistributor: "0x0000000000000000000000000000000000000000",

  //   GmxVester: "0x0000000000000000000000000000000000000000",
  //   GlpVester: "0x0000000000000000000000000000000000000000",

  //   OrderBook: "0xebD147E5136879520dDaDf1cA8FBa48050EFf016",
  //   OrderBookReader: "0xC492c8d82DC576Ad870707bb40EDb63E2026111E",

  //   PositionRouter: "0xB4bB78cd12B097603e2b55D2556c09C17a5815F8",
  //   PositionManager: "0x168aDa266c2f10C1F37973B213d6178551030e44",

  //   // UniswapGmxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
  //   ReferralStorage: "0x0000000000000000000000000000000000000000",
  //   ReferralReader: "0x0000000000000000000000000000000000000000",
  // },
  // [ARBITRUM]: {
  //   // arbitrum mainnet
  //   Vault: "0x489ee077994B6658eAfA855C308275EAd8097C4A",
  //   Router: "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064",
  //   VaultReader: "0xfebB9f4CAC4cD523598fE1C5771181440143F24A",
  //   Reader: "0x2b43c90D1B727cEe1Df34925bcd5Ace52Ec37694",
  //   GlpManager: "0x321F653eED006AD1C29D174e17d96351BDe22649",
  //   RewardRouter: "0xA906F338CB21815cBc4Bc87ace9e68c87eF8d8F1",
  //   RewardReader: "0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0",
  //   NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
  //   GLP: "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
  //   GMX: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
  //   ES_GMX: "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
  //   BN_GMX: "0x35247165119B69A40edD5304969560D0ef486921",
  //   USDG: "0x45096e7aA921f27590f8F19e457794EB09678141",
  //   ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954",
  //   StakedGmxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
  //   BonusGmxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
  //   FeeGmxTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
  //   StakedGlpTracker: "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
  //   FeeGlpTracker: "0x4e971a87900b931fF39d1Aad67697F49835400b6",

  //   StakedGmxDistributor: "0x23208B91A98c7C1CD9FE63085BFf68311494F193",
  //   StakedGlpDistributor: "0x60519b48ec4183a61ca2B8e37869E675FD203b34",

  //   GmxVester: "0x199070DDfd1CFb69173aa2F7e20906F26B363004",
  //   GlpVester: "0xA75287d2f8b217273E7FCD7E86eF07D33972042E",

  //   OrderBook: "0x09f77E8A13De9a35a7231028187e9fD5DB8a2ACB",
  //   OrderExecutor: "0x7257ac5D0a0aaC04AA7bA2AC0A6Eb742E332c3fB",
  //   OrderBookReader: "0xa27C20A7CF0e1C68C0460706bB674f98F362Bc21",

  //   PositionRouter: "0xb87a436B93fFE9D75c5cFA7bAcFff96430b09868",
  //   PositionManager: "0x75E42e6f01baf1D6022bEa862A28774a9f8a4A0C",

  //   UniswapGmxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
  //   ReferralStorage: "0xe6fab3f0c7199b0d34d7fbe83394fc0e0d06e99d",
  //   ReferralReader: "0x8Aa382760BCdCe8644C33e6C2D52f6304A76F5c8",
  // },
  // // [AVALANCHE]: {
  // //   // avalanche
  // //   Vault: "0x9ab2De34A33fB459b538c43f251eB825645e8595",
  // //   Router: "0x5F719c2F1095F7B9fc68a68e35B51194f4b6abe8",
  // //   VaultReader: "0x66eC8fc33A26feAEAe156afA3Cb46923651F6f0D",
  // //   Reader: "0x2eFEE1950ededC65De687b40Fd30a7B5f4544aBd",
  // //   GlpManager: "0xe1ae4d4b06A5Fe1fc288f6B4CD72f9F8323B107F",
  // //   RewardRouter: "0x82147C5A7E850eA4E28155DF107F2590fD4ba327",
  // //   RewardReader: "0x04Fc11Bd28763872d143637a7c768bD96E44c1b6",
  // //   NATIVE_TOKEN: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
  // //   GLP: "0x01234181085565ed162a948b6a5e88758CD7c7b8",
  // //   GMX: "0x62edc0692BD897D2295872a9FFCac5425011c661",
  // //   ES_GMX: "0xFf1489227BbAAC61a9209A08929E4c2a526DdD17",
  // //   BN_GMX: "0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2",
  // //   USDG: "0xc0253c3cC6aa5Ab407b5795a04c28fB063273894",
  // //   ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

  // //   StakedGmxTracker: "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
  // //   BonusGmxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
  // //   FeeGmxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
  // //   StakedGlpTracker: "0x9e295B5B976a184B14aD8cd72413aD846C299660",
  // //   FeeGlpTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",

  // //   StakedGmxDistributor: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
  // //   StakedGlpDistributor: "0xDd593Cf40734199afc9207eBe9ffF23dA4Bf7720",

  // //   GmxVester: "0x472361d3cA5F49c8E633FB50385BfaD1e018b445",
  // //   GlpVester: "0x62331A7Bd1dfB3A7642B7db50B5509E57CA3154A",

  // //   OrderBook: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
  // //   OrderExecutor: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
  // //   OrderBookReader: "0xccFE3E576f8145403d3ce8f3c2f6519Dae40683B",

  // //   PositionRouter: "0xffF6D276Bc37c61A23f06410Dce4A400f66420f8",
  // //   PositionManager: "0xA21B83E579f4315951bA658654c371520BDcB866",

  // //   TraderJoeGmxAvaxPool: "0x0c91a070f862666bbcce281346be45766d874d98",
  // //   ReferralStorage: "0x827ed045002ecdabeb6e2b0d1604cf5fc3d322f8",
  // //   ReferralReader: "0x505Ce16D3017be7D76a7C2631C0590E71A975083",
  // // },
  // [AVALANCHE]: {
  //   // avalanche
  //   Vault: "0x2a5bae3eaec36d147dFE859b1a68F1A5eCCB0dd5",//replaced 0x9e34FDE4Bf55061a1805C11654C21acCd34d511E
  //   Router: "0x8F16166be2B3a778AbBA0EbaaeC8f6bd8d4aD705",//replaced 0x6A154CE91003Cf4b8787280fd7C96D9BFb3f88C3
  //   VaultReader: "0xD9193457a09fA7a16e3B628129F43C1D0Ff6c6eD",//replaced 0x9DFEbCA2fa5318093016892dC772CC7132dDcE43
  //   Reader: "0x5921A3E1545A374C20Ab1f7413d78B3815b667dA",//replaced 0x8D88Aa3F648e751f989E528a32577Ee434B8e47F
  //   GlpManager: "0x9d530DdC986bd20beC3E70dC5382dF2e0a1bE7a6",//replaced 0x3a417b2949d59B129e5C6c0A52114335C780B9AE
  //   RewardRouter: "0x6E53C1cDA69858B02779FA2656F8C19a933CB9e0",//replaced 0x0AB63435EbA15CCedb44d86Cf3e2f1d8DBeB9e08
  //   RewardReader: "0xC1aEad06886edaBE97e068800EF2877568C2fFAf",//replaced 0x4Ee70052E9c64602Ab96a2A61850E72A051bbd89
  //   NATIVE_TOKEN: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
  //   GLP: "0x0E2b5428CE48c87a33Ad55A14d3b79BC7300C241",//replaced 0xA63FbC76dDaf2F800B3699a4a46C5f260E04050C
  //   GMX: "0xDc2C540Fd99eb5dC424095C8C6A63f7c805D46e9", //replaced 0x39E1Da9a034Fd5ADba01C7F6cFA8B5dE16dD908c
  //   ES_GMX: "0xafb5b77201aF3afbeE2b25c76b2DcE618a65d3bf",//replaced 0x6CdEf99C74CcF3FA65211fF547Be5dDB4A73770C
  //   BN_GMX: "0xE7807Af3c341CeA2f02a414e444aAD85B4D3d6df",//replaced 0x6e29e6db1Ea778fCC17BA575C8fB22A4dfeAE08f
  //   USDG: "0xCFc3be74C254Ca3F6B029780F804CE1289793756", //replaced 0x775003195F41AFa4f75F751c35F06B3dF76F8e04
  //   ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

  //   StakedGmxTracker: "0x81d201D05162B2E5f6B990E5950841651066438B",//replaced 0x48d7f4529f6149c5EB96AeF38534b90AD7562b4d
  //   BonusGmxTracker: "0xBb694c9D5454CbFbA8f2befde84204c7FD4D3bc5",//replaced 0xC5fcC14560781C4c9FD55d7466d781539177A3a4
  //   FeeGmxTracker: "0x71fbf7C63a196CE008aAbd2f9BB68619bFb3A837",//replaced 0xb31018C29500a565e511a0800dA87e1457CdE9b1
  //   StakedGlpTracker: "0x508a28d2d6B866cA6DE03044D32FE05E8672d55E",//replaced 0x8b498C45465f4a7e9CEc0D12Aa6a695A6b563A34
  //   FeeGlpTracker: "0xacE33f27a278f110CCcAedaD77481DE1598793b1",//replaced 0x82b84dc1A46D43747496E62BBEE2c70Ef8EE4EAD

  //   StakedGmxDistributor: "0xa7256d891F294866Bc060c764C1B3c15b66C798B",//replaced 0x4e11F35A9c226be709078787cC44375FD7c22424
  //   StakedGlpDistributor: "0x9a8fe3cCe5ca1470D213aFcb0C04ba46525c631F",//replaced 0x7ed80C511359db0E34e2FbF14aB12Ee9AfAB0Baa

  //   GmxVester: "0x57Ff6E61E703293742F1a4aF22262582E03429cb",//replaced 0x957C9a17fc5A5Fda190D1Bc4Fc1AF2B6282C2743
  //   GlpVester: "0x9024C2ac08c250fCACD1c6D60D7321c759af2cd5",//replaced 0xcf920DC4df556267A82783c4647cbe9Ce55cB1A2

  //   OrderBook: "0x2cd0640F9bA7C0a0306C92a674be4f89eF89cE80",//replaced 0xA76fB4882bcb66fBe68948B71eBe7f3B80e329Ea
  //   OrderExecutor: "0xA76fB4882bcb66fBe68948B71eBe7f3B80e329Ea",//replaced
  //   OrderBookReader: "0xD44d5D7605c388bE8A9eeEBc37Ba24188CD1e634",//replaced 0x1d47908DC429b00611bd11AF78A7D39E7Dc6bCa2

  //   PositionRouter: "0xA8A8ef28cE7cDC25319f0E13fD8245F26caf8849",//replaced 0xD3D403595ccBB99537Af13099aC89A102AAd70D1
  //   PositionManager: "0xda18A5372fF256314fd3654FA074B4d949aBE823",//replaced 0xD5326A526f78667375D9D5dA7C739e261Df52fe6

  //   TraderJoeGmxAvaxPool: "0x153DA2C6B4Ed40B4C67a8c95EE45E93B5c85786F",//replaced
  //   ReferralStorage: "0xF51Def0A8A991E2Aa227643271d290c2DAa8cFCe",//replaced 0xa4Ac7025c01e8fa2bfB7f274913e76b5d4d719de
  //   ReferralReader: "0x69A0542B67622056B401159360692b1011B23B87",//replaced 0xDe0D6DbCCB35b92e0952C51ddDD81C7A86C5E480
  // },
  // [GOERLI_TESTNET]: {
  //   // goerli
  //   Vault: "0xDAaD544a99cE12348375f7eE87ED7E135df6c251",
  //   Router: "0xE28bc7762065B8c20C780E3B9e2562EE7b994465",
  //   VaultReader: "0x0e46636862C0507FbDB8bD93B15f7745E8dbcEdc",
  //   Reader: "0x56a79960d02456De0434D55ef65FA90BF39dbA20",
  //   GlpManager: "0x3c689f719D1a6f1F3679f629bde2cb08742014Cf",
  //   RewardRouter: "0xDf16a652Cd152edb8F8b139C7E20576465f73632",
  //   RewardReader: "0x7D45596211F09c0AdBceB0f02f1BF6E1926f5BA6",
  //   NATIVE_TOKEN: "0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6",
  //   GLP: "0xAd20dbA3AC166690d9d2cf2624eb9af6892FA27B",
  //   GMX: "0x2F85207AAfF05040e7fcE48c5ed80d01c4C38597",
  //   ES_GMX: "0x57E040359Be0a5B4b0A2f98D6651BffAa5e1E0E1",
  //   BN_GMX: "0x90d189AD74D5B286653b5865f59B9fE99af8a96a",
  //   USDG: "0x049276CB253F73C052ADEc1D22288c75Bf2095f4",
  //   ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

  //   StakedGmxTracker: "0x5f919c59dcdb9419daFc0F5272C53bed20f3cfB3",
  //   BonusGmxTracker: "0x72b67a834662302d141a31C18dce72EE9705fe8A",
  //   FeeGmxTracker: "0x98eee67707C98efD2aD51caaa52E28b57f8d3001",
  //   StakedGlpTracker: "0x73FA13a46e55176D5FA9Ee75A74887492CFC85de",
  //   FeeGlpTracker: "0xFB2Ac7632BCfA9E3DD160B00F9CBcbaf53B2F48d",

  //   StakedGmxDistributor: "0x1087a968c88b2E903834598cc33fbB138F398b09",
  //   StakedGlpDistributor: "0x02eaf961fA00519210AAE896638478bd793685ae",

  //   GmxVester: "0xf93ea218A14f14BFfeA0Ef9b0628890ecFcafbF5",
  //   GlpVester: "0xD2d115640dd906b517622e784E6B205C53A93500",

  //   OrderBook: "0x2D8BF359Fe5969448bE386BE0C66A9111c1Dd8e1",
  //   OrderExecutor: "0xA76fB4882bcb66fBe68948B71eBe7f3B80e329Ea",//replaced //unused
  //   OrderBookReader: "0x3C54B4fE089472A7F963A0803278f0eC2632014F",

  //   PositionRouter: "0xa0b9783C1cBf4096AC8D5876920855F333D58c2f",
  //   PositionManager: "0x38ba6Ae837450612E787AD7f2A72D13B048c300F",

  //   TraderJoeGmxAvaxPool: "0xAb9176a0A6263871c3b6A531724e09e38277663C",//replaced
  //   ReferralStorage: "0x990B504aa9717870b9D5C28320af8ce4B59a5A9F",
  //   ReferralReader: "0xF7D307012282496541ec698E57C1F4b082B73a21",
  // },
  // ORG
  // [POLYGON]: {
  //   // polygon
  //   Vault: "0x0e1D69B5888a0411Fe0A05a5A4d2ACED4305f67c",
  //   Router: "0xb12d126a1b6Eb619625516267d4Af7b2bCdf5aa7",
  //   VaultReader: "0x8466FD79C275fEA91D4513c41671629E216C546B",
  //   Reader: "0x53be50575b278183DD61183Df41402c9faaef958",
  //   GlpManager: "0x11A45c257DFC62d58C3451554D0e87aeE77AeF0c",
  //   RewardRouter: "0x4Dec2609BB3B3300cDFE53169585e3FaD34d2437",
  //   RewardReader: "0x91880439289C3292b6F0e7756aed59ffF7f5f146",
  //   NATIVE_TOKEN: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  //   GLP: "0x02712365E3cF832106b5e3c0f6c6e78b5D4670Dc",
  //   GMX: "0x0709E962221dd8AC9eC5c56f85ef789D3C1b9776",
  //   ES_GMX: "0xC9c8f6Eed3BD0074Ef8dbE104eFA7eeAf66e2E0D",
  //   BN_GMX: "0xbFdA0eecF09c5C0a2f49c394CFD63de3B2fb99b7",
  //   USDG: "0x183344fCBeFAe888c302B3a7C9fe8E5b78Ea9777",
  //   ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

  //   StakedGmxTracker: "0xa326cF15E65FDEA84E63506ed56c122bC9E9A4BE",
  //   BonusGmxTracker: "0x91594d93268A653504Fb989549980C9370Fa1bD0",
  //   FeeGmxTracker: "0x5bdF6A3945Dbb984263E84D4F07C44aE17FD4be4",
  //   StakedGlpTracker: "0xF03e83D860ce1659cA508435b197A061011862b6",
  //   FeeGlpTracker: "0xb7ac97685354ED4e41b1C3A8ae0f4405e195506c",
  //   // its just swapped staked and fee

  //   StakedGmxDistributor: "0xD936010CaAf3d3fE2831D25b04F29aD933975816",
  //   StakedGlpDistributor: "0xe4a5CBF1194382D53517b281D31727DAc95EBE77",

  //   GmxVester: "0xF6F87b742a79384D374DAba9569d789bEAeaaC5B",
  //   GlpVester: "0x1Cd79a2016b6D8CDfE8fd9DF525560db383a3f0B",

  //   OrderBook: "0xE02b257bb6cA63F0CdfdD7aeDa3e43E3cBe1919A",
  //   OrderExecutor: "0xA76fB4882bcb66fBe68948B71eBe7f3B80e329Ea",
  //   OrderBookReader: "0xdD6b523F25e3a961FfA9BC23a3490650054B8D59",

  //   PositionRouter: "0xDdFe6F76bC9727e3f512B617AB41B580C699fC31",
  //   PositionManager: "0x65dA71699fcdCA1ab46A932Fb01E5F573a5D9B74",

  //   TraderJoeGmxAvaxPool: "0xB3fD0fa9795fae63F46c8BCa4c9A6c6Ad1b28f7F",
  //   ReferralStorage: "0x2034dE9d504b21AA2611Ac1410642D705499894c",
  //   ReferralReader: "0xC33879EE2A73f8EE84Cb996554e3CfC0c4d2F906",
  // },

  // Ganache
  [POLYGON]: {
    Vault: "0x497e7d78f7D0c0B5187D10042a150Ae3F64E6a4c",
    Router: "0xFdae06ad8b5dd6f00E9F3710C9d4B2ef89e041bC",
    VaultReader: "0xe801Be809D3B28324Dd37Ca1E1d09D5Df61c6DD1",
    Reader: "0x9Bc09498D9a0Dcd530BD819c96D5Ff90A608aFC8",
    GlpManager: "0xf78008d6E08A0Ab748bFeF9Fc32a0D9aB2D9b0Cb",
    RewardRouter: "0x7f5A0E85e187DCC2d3D5e6C15BfC87bf97aFA69A",
    RewardReader: "0x2ad0F99cC226d183ABddfC4F391387DA3117a94F",
    NATIVE_TOKEN: "0x5f0b1a82749cb4e2278ec87f8bf6b618dc71a8bf",
    GLP: "0xE50c4a68fe2799979c7008Ca682d508b50CfA201",
    GMX: "0x70e46adCAD0151a8BADAB1B1A82A871ee1324425",
    ES_GMX: "0xfbeB4d4CbC3C244675BfB816532879Fc86823d71",
    BN_GMX: "0xfeb252C927130fFb667d130327E772c82545f9B8",
    USDG: "0x671851d0D4CC95aA82d54B1573dD6c64B8dab7B1",
    ES_GMX_IOU: "0x77C0F2965dD38Fc4d235A9E04833A2DACD09Ff5A",

    StakedGmxTracker: "0xdF3D12dbBB31196d0e69BDEad573d9977A9c17FC",
    BonusGmxTracker: "0xcD39110112deF0E9c1633502838972958EB88160",
    FeeGmxTracker: "0x7Ef9DD8baD2f932c32C0E46106850FEEA9EFE19b",
    StakedGlpTracker: "0x0DB9BC0C7C266603E0312bf77705eAa32f410Ba5",
    FeeGlpTracker: "0xEe6CC4388626091bD6475D661034609422e23Dd2",

    StakedGmxDistributor: "0x9e333Edcc500619677D8Dea1B1448872186CFFd0",
    StakedGlpDistributor: "0x550AAA07Fd3AaA8346C63e1aA66bc94E679a7451",

    GmxVester: "0x654E7620816AA4DEEF0Dfa46fDc329CEA5ff1B78",
    GlpVester: "0x47b9dBD1480e1278Cf3c03EF27770e5fD1106cE2",

    OrderBook: "0x5bB7DfcAB5a5FBB1aF0693D76fF7CA7d5c54953f",
    OrderExecutor: "0xc6447d4C91D76515A1Fa610c42019EB41eb50476",
    OrderBookReader: "0x68b48df19e028FEDF4B89308Ea48A87C66E33866",

    PositionRouter: "0x2db3A5b0b878BE6D7b888c96568fC836B44AfD96",
    PositionManager: "0x426ce2B3C1F1fB16F2f812b595683d59e0e6f608",

    TraderJoeGmxAvaxPool: "0x96c3354886b039513bC3256f6Ff5465e1D65a5B7",
    ReferralStorage: "0x53BbD07009642F28E5b8e0e63088c2290EE0b5b2",
    ReferralReader: "0x24b2adDB7268881eee185D2ce9902f64554A55Fc",

    // Vault: "0x1F8d2a3e6c6dF169f8Ae242D44cD86622d150c2a",
    // Router: "0x9E2e466326451fb03cE729c8A0F51e05F147384B",
    // VaultReader: "0x55B35bfed74c26a81F1Ed9a0DCC5f3368dAA2eea",
    // Reader: "0x680cc80977Ba481eAA34c0B0C0EBD6f552ab0AE2",
    // GlpManager: "0x33cbF2450DC5E97f65bd158B38990c895b558E62",
    // RewardRouter: "0x7487b265FA3Be440dc47Fc2C1693009895a4e5e4",
    // RewardReader: "0x8a6d74aCf023987aEA2c100D04b5c3Cd241010EB",
    // NATIVE_TOKEN: "0x5F0b1a82749cb4E2278EC87F8BF6B618dC71a8bf",
    // GLP: "0xC6d43F54cEEC425Bda12d6BC119B139Fd448489A",
    // GMX: "0x2b563CEDb4F7a38d4467cfCe2e72128Eef582495",
    // ES_GMX: "0x12Fa3156C6bc0E03Ffa045226db62eAa3F264C2f",
    // BN_GMX: "0x0817E61B378D79c0a9661720C5b0634eb2b2042F",
    // USDG: "0x9E6840e7A91E06598e206E287A6f5Bad8EB25566",
    // ES_GMX_IOU: "0x58f975cF68314f5Dc6472a1Ba0295a851AC47DF7",

    // StakedGmxTracker: "0xa04fE54e8ca1A46F06A0e007D45D91a0895d77eE",
    // BonusGmxTracker: "0xa77b9BBFa781271395261853b4b57bA9C8089DF1",
    // FeeGmxTracker: "0xb79061da2F1F9e30F5ee4EDe35A650ECdfad49Fe",
    // StakedGlpTracker: "0x81Af578C22C7468D9265032282654D78c8D3d69e",
    // FeeGlpTracker: "0xE5D29133a7F4a341EC2d8FeF10951a94Bf380345",

    // StakedGmxDistributor: "0xf37f3Eeb29557dD9b8f277c5491Ed4f26D1ABA01",
    // StakedGlpDistributor: "0x6B5724ef9353E0710bDE813fC5a369b980198005",

    // GmxVester: "0x0f562b13B00c201bc73e6784E31A4c24cDe60f2E",
    // GlpVester: "0x6d3507a990A043CB022a646Dd3784BAA3176E289",

    // OrderBook: "0x53554085f22e2F89EBD1CBa95042e76805B0747D",
    // OrderExecutor: "0xef505a633A84026A87A19273AE05A0eAaeAaBAe2",
    // OrderBookReader: "0x8E6C9Fd1865394B9cb656Da5D3f1f12F2cA2C23e",

    // PositionRouter: "0xb04F81Ec7406526351FDEb7B13Dc040D231d5b30",
    // PositionManager: "0x545F2B6d5785338fCdD491B8F4D9620A130f0e67",

    // TraderJoeGmxAvaxPool: "0xC925847c9a6b3D1A3f42f17bFb9Edf6fbFF1Ae71",
    // ReferralStorage: "0x0c35fF828Ec37C6B08a38872CaA1CBCe25F17C78",
    // ReferralReader: "0x71dc7a6f3B156B2c47183cd69283a795b90fa46E",

    // Vault: "0xED2B1daF253b552ab2842d06C40e8E6b0cC798d7",
    // Router: "0xD2CBD4039C69999D58A7BC415a0322BF490124f0",
    // VaultReader: "0xDe6F0AAB5E3DE29463d4Fe62181286fC138D35eC",
    // Reader: "0x605b065d54Bc3BEA3a75aD537c54584b7d5D9133",
    // GlpManager: "0x7eC0dC671104ef0d9cDFA0A18fBFf6eAC767392d",
    // RewardRouter: "0xAe2B7494AfaA3A94cc995e4D04ddE9062Eab9214",
    // RewardReader: "0x34E4Dd01fb70C79E6F9B59AF4c5eb85AEc7303Ea",
    // NATIVE_TOKEN: "0x99c0aea4d88e69c9e3992f29c77207dcb393953c",
    // GLP: "0x6D365Dc66F50Ca2a0309f35CBF4Fde176F8351F1",
    // GMX: "0xf6960f9F1cF9Db409994ab928e4A195744AAd44D",
    // ES_GMX: "0xa54F3632db9287a06e4a437672a9686b5B5376bB",
    // BN_GMX: "0x32eD5D0742616EC8561CDA1E50Fc3B496de8BC68",
    // USDG: "0x6846B31B770089BAdf12D9361844a6552240fF5a",
    // ES_GMX_IOU: "0x3f90BfD2289CFaCD0A4aCaeDF1Ff39C3838861d6",

    // StakedGmxTracker: "0xeb38c0183507391A6D27EB3a3CF7ECBA132aa3E4",
    // BonusGmxTracker: "0x0186FC8aC8895D9efaEeCF67D57e5Aa161C81fF5",
    // FeeGmxTracker: "0xE49FF087ff442EBa8cBE64fA2961795CaD8d9059",
    // StakedGlpTracker: "0xfae2f51Fd349Ac61037263727e2786aca53b91c1",
    // FeeGlpTracker: "0xF4b6927BB8F96A72d4deE8c2B4410B582e983442",

    // StakedGmxDistributor: "0x4eAfDA19dA0536C6B3b003cE927f5F48944a39aa",
    // StakedGlpDistributor: "0x068CAB2Ec74b6073fD4DD3063C7FB751169ae881",

    // GmxVester: "0x7A3dC019806cb6ca6248E29cd54529351C6fB0e7",
    // GlpVester: "0xe1cFF21c029e792e5eBe2Da244b7629682477600",

    // OrderBook: "0x8FaDc94D652B8d254927b799e947133E3aEfe55e",
    // OrderExecutor: "0x837627e836B186Bf4a9e34c3DE63Fc3153200933",
    // OrderBookReader: "0x89F226d2C1Fd7d465799c62BE7854983eF83c2c2",

    // PositionRouter: "0x22064De80C618C646d2Ad941ccE53292B109cB17",
    // PositionManager: "0x68fDfaDe597CaD310dC7a92E6D07B8FE03aaF901",

    // TraderJoeGmxAvaxPool: "0xC925847c9a6b3D1A3f42f17bFb9Edf6fbFF1Ae71",
    // ReferralStorage: "0x2aD8CDA07885c0d19Fa8E25A9324f4fE510E97E9",
    // ReferralReader: "0xd4eB726466eEebf7Ef55A9F80F2B9920b612B6fA",
  },
};

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}
